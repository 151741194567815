import { AgentConfigOptions, init as initApm } from '@elastic/apm-rum';
import { getFeatureFlag } from '@platform24/clinic-utils';

/*
window.localStorage.setItem('apmConfig', JSON.stringify({
  active: true,
  serverUrl: 'http://localhost:8200',
  serverUrlPrefix: '/intake/v3/rum/events',
  transactionSampleRate: 1.0,
  logLevel: 'debug',
}));
*/

const localApmConfigOverrides: AgentConfigOptions = JSON.parse(window.localStorage.getItem('apmConfig') ?? '{}');
const enableApm = getFeatureFlag('FF_ENABLE_ELASTIC_APM');

declare global {
  interface Window {
    __apm?: ReturnType<typeof initApm>;
    // Need this because clinic-runtime cannot obtain a token itself, another MFE needs to provide it later
    __apmGetAuthToken?: () => string | undefined;
    __frontendConfig?: Record<string, string>;
  }
}

// https://www.elastic.co/guide/en/apm/agent/rum-js/5.x/configuration.html
window.__apm = initApm({
  active: enableApm,

  // Setting this value to any number above 2 will compress the events (transactions and errors) payload sent to the server.
  // This feature requires APM Server >= 7.8.
  apiVersion: 3,

  disableInstrumentations: [
    // PII exposure risk
    'error',
  ],

  // https://medium.com/codex/elastic-real-user-monitoring-get-past-ad-blockers-bb7c55a18d45
  serverUrl: '/rest',
  serverUrlPrefix: '/elastic-apm-rum-v3',

  serviceName: 'clinic-ui',

  // default to 1%
  transactionSampleRate: parseFloat(window.__frontendConfig?.FRONTEND_CONFIG_APM_TRANSACTION_SAMPLE_RATE ?? '0.01'),

  apmRequest: ({ xhr }) => {
    const token = window.__apmGetAuthToken?.();

    if (!token) {
      return false;
    }

    xhr.setRequestHeader('Authorization', `Bearer ${token}`);

    return true;
  },

  ...localApmConfigOverrides,
});

type FixedPayloadType = {
  transactions: Array<{
    name: string;
    type: string;
  }>;
};

// Not an exhaustive list, but a good start
const EXCLUDED_HTTP_REQUEST_TRANSACTION_NAMES: Array<string | RegExp> = [
  'POST /rest/attestations/clinic/attestations/v3/count-unattested',
  'POST /rest/healthmanager/clinic/appointments/v1/billing/count',
  'POST /rest/healthmanager/clinic/appointments/v1/drop-in/count',
  'POST /rest/healthmanager/clinic/consultations/v1/count',
  /^GET \/rest\/healthmanager\/shifts\/v1\?filter=.+$/,
];

window.__apm.addFilter(__payload => {
  const payload = __payload as FixedPayloadType;

  payload.transactions = payload.transactions.filter(transaction => {
    const excluded =
      transaction.type === 'http-request' &&
      EXCLUDED_HTTP_REQUEST_TRANSACTION_NAMES.some(pattern =>
        typeof pattern === 'string' ? pattern === transaction.name : pattern.test(transaction.name),
      );

    return !excluded;
  });

  return payload;
});

const INTERESTING_ATTRIBUTE_NAMES: string[] = [
  'data-transaction-name', // highest prio
  'name',
  'href',
  'data-testid',
  'data-test-id',
  'aria-label',
  'role',
  'class', // lowest
];

let lastClickedElement: Element | null = null;

document.addEventListener(
  'click',
  event => {
    if (event.target instanceof Element) {
      lastClickedElement = event.target;
    }
  },
  { capture: true }, // avoid propagation stops
);

window.__apm.observe('transaction:start', transaction => {
  if (transaction.type !== 'user-interaction') {
    return;
  }

  // Ensure that this runs after lastClickedElement was set to the right value
  setTimeout(() => {
    transaction.name = `Click - ${getUsefulElementName(lastClickedElement)}`;
  }, 0);
});

function getUsefulElementName(element: Element | null) {
  const ancestors = element ? getPathToClosestInterestingAncestor(element) : null;

  if (!ancestors) {
    return 'unknown element';
  }

  return ancestors.map(describeElement).join(' > ');
}

function describeElement(element: Element) {
  let description = element.tagName.toLowerCase();

  for (const attributeName of INTERESTING_ATTRIBUTE_NAMES) {
    let attributeValue = element.getAttribute(attributeName);
    let operator = '=';

    if (attributeName === 'class' && attributeValue) {
      const styledComponentsClassSuffix = /(__|-)sc-/;
      const classParts = attributeValue.split(styledComponentsClassSuffix);

      if (classParts.length > 1) {
        attributeValue = classParts[0];
        operator = '*=';
      }
    }

    if (attributeValue) {
      description += `[${attributeName}${operator}"${attributeValue}"]`;
      break;
    }
  }

  return description;
}

function getPathToClosestInterestingAncestor(element: Element) {
  for (const attributeName of INTERESTING_ATTRIBUTE_NAMES) {
    const predicate = (element: Element) => Boolean(element.getAttribute(attributeName));
    const ancestors = getPathToClosestMatchingAncestor(element, predicate);

    if (ancestors) {
      return ancestors;
    }
  }

  return null;
}

function getPathToClosestMatchingAncestor(initialElement: Element, predicate: (element: Element) => boolean) {
  let currentElement = initialElement;
  const elements: Element[] = [];

  // eslint-disable-next-line no-constant-condition
  while (true) {
    elements.unshift(currentElement);

    if (predicate(currentElement)) {
      return elements;
    }

    if (currentElement.parentNode?.nodeType !== Node.ELEMENT_NODE) {
      return null;
    }

    currentElement = currentElement.parentNode as Element;
  }
}
